import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  useGetL1NavBarColorVariants,
  useJobPagePath,
  useUser,
} from "@js/apps/common/hooks";
import { applySpacesSubElementsToFreelancerNavbar } from "@js/apps/common/hooks/use-freelancer-sidebar/utils";
import { useFreelancerMyWorkMainPath } from "@js/apps/freelancer/hooks/use-freelancer-my-work-main-path";
import { useGetMySpacesQuery } from "@js/apps/spaces/api";
import { useMySpacesSubscribe } from "@js/apps/spaces/hooks/use-my-spaces-subscribe";
import {
  findCurrentNavItem,
  getNavMenuColorVariant,
} from "@js/layouts/app/utils/common-navigation";
import { getFreelancerNavMenuItems } from "@js/layouts/app/utils/freelancer-navigation";

export const useFreelancerSidebar = () => {
  const user = useUser();
  const { pathname } = useLocation();
  const { data } = useGetMySpacesQuery(undefined, { skip: !user?.is_verified });
  const { jobsPath } = useJobPagePath();
  const { myWorkMainPath } = useFreelancerMyWorkMainPath();
  const L1ColorVariant = useGetL1NavBarColorVariants(pathname);
  useMySpacesSubscribe();

  const FREELANCER_ITEMS = useMemo(
    () => getFreelancerNavMenuItems({ jobsPath, myWorkMainPath }),
    [jobsPath, myWorkMainPath],
  );

  const freelancerSidebarItems = useMemo(() => {
    const FREELANCER_ITEMS_WITH_SPACES_SUBLINKS =
      applySpacesSubElementsToFreelancerNavbar(FREELANCER_ITEMS, data);

    return FREELANCER_ITEMS_WITH_SPACES_SUBLINKS.filter((item) => {
      return item.canAccess;
    });
  }, [FREELANCER_ITEMS, data]);

  const currentLinkVariant = useMemo(() => {
    const currentLinkItem = findCurrentNavItem({
      items: FREELANCER_ITEMS,
      exact: true,
      pathname,
    });
    return getNavMenuColorVariant(currentLinkItem, pathname);
  }, [pathname, FREELANCER_ITEMS]);

  return {
    sidebarItems: freelancerSidebarItems,
    itemVariant: L1ColorVariant || currentLinkVariant,
  };
};
