import type { GetEmployerInvoiceStatisticsResponse } from "@js/apps/invoices/types";

import { EmployerInvoicesStatisticValue } from "./employer-invoices-statistic-value";
import { EmployerInvoicesStatisticWrapper } from "./employer-invoices-statistic-wrapper";

type EmployerInvoiceOverdueStatisticProps = {
  isLoading: boolean;
  data:
    | Pick<
        GetEmployerInvoiceStatisticsResponse,
        "overdue_amount" | "overdue_invoices"
      >
    | undefined;
};

export const EmployerInvoicesOverdueStatistic = ({
  isLoading,
  data,
}: EmployerInvoiceOverdueStatisticProps) => {
  const isOverdueHigherThanZero =
    !!data?.overdue_amount &&
    !isNaN(+data?.overdue_amount) &&
    +data?.overdue_amount > 0;

  return (
    <EmployerInvoicesStatisticWrapper data-testid="overdue-statistics">
      <EmployerInvoicesStatisticValue
        isLoading={isLoading}
        value={data?.overdue_amount}
        label="Overdue invoices"
        sx={{
          color: isOverdueHigherThanZero ? "var(--negative-2)" : undefined,
        }}
        helperText={!isOverdueHigherThanZero && "There are no overdue invoices"}
      />
    </EmployerInvoicesStatisticWrapper>
  );
};
