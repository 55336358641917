import {
  BriefcaseIcon,
  EarnIcon,
  JobsIcon,
  StarSVGIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { Employer } from "@js/types/employer";

import type { DashboardNavItem } from "../types";

import {
  EMPLOYER_ROUTES,
  getEmployerInvoicesMainRoute,
} from "./employer-routes";

type GetEmployerNavItemsProps = {
  canViewInvoices: boolean;
  employer: Employer;
};

const StyledStarIcon = () => {
  return <StarSVGIcon sx={{ path: { strokeWidth: 2 } }} />;
};

export const EMPLOYER_NAV_ITEMS = {
  HOME: {
    label: "Home",
    path: EMPLOYER_ROUTES.HOME,
    icon: WelcomeIcon,
  },
  MY_JOBS: {
    path: EMPLOYER_ROUTES.MY_JOBS,
    label: "My Jobs",
    icon: JobsIcon,
  },
  MY_TALENT: {
    path: EMPLOYER_ROUTES.MY_TALENT,
    label: "My Talent",
    icon: StyledStarIcon,
  },
  BROWSE_TALENT: {
    path: EMPLOYER_ROUTES.TALENT,
    label: "Browse Talent",
    icon: BriefcaseIcon,
  },
  INVOICES: {
    path: EMPLOYER_ROUTES.INVOICES,
    label: "Invoices",
    icon: EarnIcon,
    getIsActive: ({ pathname }) => pathname.startsWith(`/employer/invoices`),
  },
} as const satisfies Record<string, DashboardNavItem>;

export const getEmployerNavItems = ({
  canViewInvoices,
  employer,
}: GetEmployerNavItemsProps): DashboardNavItem[] =>
  [
    EMPLOYER_NAV_ITEMS.HOME,
    EMPLOYER_NAV_ITEMS.MY_JOBS,
    EMPLOYER_NAV_ITEMS.MY_TALENT,
    EMPLOYER_NAV_ITEMS.BROWSE_TALENT,
    canViewInvoices && {
      ...EMPLOYER_NAV_ITEMS.INVOICES,
      path: getEmployerInvoicesMainRoute(employer),
    },
  ].filter(Boolean) as [];
