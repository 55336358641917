import { useCallback, useEffect, useMemo, useState } from "react";
import { formValueSelector, SubmissionError, submit } from "redux-form";
import * as Sentry from "@sentry/react";
import { useStripe } from "@stripe/react-stripe-js";

import {
  useLazyGetPaymentTransactionQuery,
  usePayInvoicesMutation,
} from "@js/apps/payments/api";
import {
  openACHPaymentSuccessModal,
  openCCPaymentMethodSuccessModal,
} from "@js/apps/payments/components/payment-success-modal";
import type { PayFormValues } from "@js/apps/payments/forms/pay";
import { PAY_FORM_ID } from "@js/apps/payments/forms/pay";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { PaymentMethod, PaymentTransaction } from "@js/types/payments";
import { getErrorsForRequiredFields, typeGuard } from "@js/utils";

import {
  employerInvoicesApi,
  useCalculateEmployerInvoicesCreditCardFeeMutation,
  useGetEmployerInvoicesMinimalQuery,
} from "../../api";
import { openCcPaymentFeeModal } from "../../components/cc-payment-fee";
import { openPayDependentInvoiceFirstMessage } from "../../components/pay-dependent-invoices-first";
import {
  getEmployerInvoicesThatCannotBePaid,
  getInvoicesTotal,
} from "../../logic";

const payFormValuesSelector = formValueSelector(PAY_FORM_ID);

type UsePayEmployerInvoicesProps = {
  onInvoicesPaymentFailed?: () => void;
  onCloseSuccessModal?: () => void;
  closePaymentModal: () => void;
  invoiceIds: number[];
};

export const usePayEmployerInvoices = ({
  onInvoicesPaymentFailed,
  closePaymentModal,
  invoiceIds,
  onCloseSuccessModal,
}: UsePayEmployerInvoicesProps) => {
  const dispatch = useAppDispatch();
  const [invoiceIdsToPay, setInvoiceIdsToPay] = useState<number[]>(invoiceIds);

  const {
    data: invoicesData,
    isFetching: isFetchinginvoicesData,
    isError,
  } = useGetEmployerInvoicesMinimalQuery(
    { ids: invoiceIdsToPay },
    { skip: !invoiceIdsToPay.length },
  );

  const [
    calculateCreditCardFee,
    { isLoading: isCalculatingCreditCardFee, data: totalIncludingCCPaymentFee },
  ] = useCalculateEmployerInvoicesCreditCardFeeMutation();

  useEffect(() => {
    if (isFetchinginvoicesData) {
      return;
    }

    const hasFailedToFetchInvoicesData = isError || !invoicesData?.length;
    if (!hasFailedToFetchInvoicesData) {
      return;
    }
    Snackbar.error("Failed to fetch invoices data.");
  }, [invoicesData, isError, isFetchinginvoicesData]);

  const [payInvoices] = usePayInvoicesMutation();
  const [loading, setLoading] = useState(false);
  const [payRequestError, setPayRequestError] = useState<
    Record<string, string[]> | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [
    getTransaction,
    { data: transactionResult, isFetching: fetchingTransaction },
  ] = useLazyGetPaymentTransactionQuery();

  const invalidateData = useCallback(() => {
    dispatch(
      employerInvoicesApi.util.invalidateTags([
        "EmployerConsolidatedInvoices",
        "EmployerInvoiceStatusCounts",
        "EmployerInvoiceStatistics",
        "EmployerInvoicesMinimal",
        "EmployerInvoicesIds",
        "EmployerInvoices",
      ]),
    );
  }, [dispatch]);

  const isTransactionProcessing = useMemo(() => {
    return (
      transactionResult?.status === ENUMS.PaymentStatus.PROCESSING ||
      fetchingTransaction
    );
  }, [fetchingTransaction, transactionResult?.status]);

  const checkPaymentMethodFee = async (
    paymentMethod: PaymentMethod,
    processPayment: () => void,
  ) => {
    if (!invoicesData) {
      return;
    }

    if (
      paymentMethod?.content_type !==
      ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
    ) {
      return processPayment();
    }

    try {
      const creditCardPaymentFee = await calculateCreditCardFee({
        invoices: invoicesData.map((invoice) => invoice.id),
      }).unwrap();

      openCcPaymentFeeModal({
        creditCardPaymentFee,
        onConfirm: () => {
          processPayment();
        },
      });
    } catch (error) {
      const creditCardFeeErrorMessage =
        typeGuard<unknown, { data: unknown }>(error, "data") &&
        typeGuard<unknown, { _error: string }>(error.data, "_error")
          ? error.data._error
          : "Failed to calculate credit card payment fee.";

      Snackbar.error(creditCardFeeErrorMessage);
    }
  };

  const getCanSelectedInvoicesBePaid = async () => {
    if (!invoicesData) {
      return false;
    }

    const invoicesToPay = invoicesData;
    const cannotBePaid = getEmployerInvoicesThatCannotBePaid(invoicesToPay);

    if (cannotBePaid) {
      openPayDependentInvoiceFirstMessage({
        invoicesThatCannotBePaid: cannotBePaid,
        onConfirmIncludingInvoices: (toInclude) => {
          setInvoiceIdsToPay((prevSelected) => [...prevSelected, ...toInclude]);
        },
      });

      return false;
    }

    return true;
  };

  const selectedPaymentMethod = useAppSelector((state) =>
    payFormValuesSelector(state, "paymentMethod"),
  );

  const valueToPay = useMemo(() => {
    if (!invoicesData) {
      return "";
    }

    const invoicesTotal = getInvoicesTotal(invoicesData).toString();
    if (!selectedPaymentMethod) {
      return invoicesTotal;
    }

    if (
      selectedPaymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeACHPaymentMethod
    ) {
      return invoicesTotal;
    }

    return totalIncludingCCPaymentFee
      ? totalIncludingCCPaymentFee.total_with_fee
      : invoicesTotal;
  }, [selectedPaymentMethod, totalIncludingCCPaymentFee, invoicesData]);

  const stripe = useStripe();
  const isPaying = loading || isTransactionProcessing;
  const isLoading =
    isPaying || isFetchinginvoicesData || isCalculatingCreditCardFee;

  const onInvoicesPaymentFailedRef = useEffectRef(onInvoicesPaymentFailed);
  const errorMessageRef = useEffectRef(errorMessage);
  useEffect(
    () => () => {
      if (!errorMessageRef.current) {
        return;
      }

      invalidateData();
      onInvoicesPaymentFailedRef.current?.();
    },
    [errorMessageRef, invalidateData, onInvoicesPaymentFailedRef],
  );

  const handleTransactionCreated = (
    transaction: PaymentTransaction,
    paymentMethod: PaymentMethod,
  ) => {
    if (!stripe) {
      console.error("Stripe is not available");
      Snackbar.error(
        "Stripe is not available, please refresh the page and try again.",
      );
      return;
    }

    if (
      paymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
    ) {
      setLoading(true);

      if (!transaction.gateway_response.client_secret) {
        setLoading(false);
        setErrorMessage("No Client secret");

        Sentry.captureException(
          // @ts-ignore -- Error 2nd arg isn't supported by all browsers
          new Error(`Payment Failed: No clientSecret`),
        );
        return;
      }

      stripe
        .confirmCardPayment(transaction.gateway_response.client_secret, {
          payment_method: paymentMethod.method.payment_method_id,
        })
        .then((response) => {
          if (response.error) {
            setLoading(false);
            setErrorMessage(
              response.error.message || "Unexpected stripe error.",
            );

            Sentry.captureException(
              // @ts-ignore -- Error 2nd arg isn't supported by all browsers
              new Error(`Payment Failed: ${response.error?.code}`, {
                cause: response.error,
              }),
            );
          } else {
            setLoading(false);
            setErrorMessage(null);

            pollTransactionStatus(transaction);
          }
        });
    } else if (
      paymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeACHPaymentMethod
    ) {
      setLoading(false);
      setErrorMessage(null);

      invalidateData();
      closePaymentModal();

      openACHPaymentSuccessModal({ onClose: onCloseSuccessModal });
    } else {
      // Depends on payment method but in most cases (for immediate payments)
      // probably just call `pollTransactionStatus(transaction);` here.
    }
  };

  const handleSubmitPaymentForm = async (values: PayFormValues) => {
    const { paymentMethod } = values;
    setLoading(true);

    return payInvoices({
      invoices: invoiceIdsToPay,
      payment_method: paymentMethod.id,
      amount: valueToPay,
    })
      .unwrap()
      .then((transaction) => {
        setPayRequestError(undefined);
        return handleTransactionCreated(transaction, paymentMethod);
      })
      .catch((error) => {
        dispatch(
          employerInvoicesApi.util.invalidateTags(["EmployerInvoicesMinimal"]),
        );
        Sentry.captureException(new Error(JSON.stringify(error)));

        const isAmountError = Object.keys(error.data).includes("amount");
        const isAmountRequiredError =
          isAmountError &&
          !!Object.keys(getErrorsForRequiredFields(error.data, ["amount"]))
            .length;

        if (isAmountError && !isAmountRequiredError) {
          Snackbar.error(error.data["amount"]);
        }

        if (error.data && typeof error.data === "object") {
          setPayRequestError(error.data);
        }

        setLoading(false);
        throw new SubmissionError(error.data);
      });
  };

  const pollTransactionStatus = async (transaction: PaymentTransaction) => {
    return getTransaction(transaction.id)
      .unwrap()
      .then((updatedTransaction) => {
        if (updatedTransaction.status === ENUMS.PaymentStatus.SUCCESS) {
          setErrorMessage(null);

          closePaymentModal();

          invalidateData();

          openCCPaymentMethodSuccessModal({ onClose: onCloseSuccessModal });
        } else if (updatedTransaction.status === ENUMS.PaymentStatus.FAILURE) {
          setErrorMessage(
            `Payment failed. Please try again. ${transaction.error || ""}`,
          );
        } else {
          setTimeout(() => pollTransactionStatus(transaction), 2000);
        }
      });
  };

  const onPayClick = async () => {
    const selectedInvoicesCanBePaid = await getCanSelectedInvoicesBePaid();
    if (!selectedInvoicesCanBePaid) {
      return;
    }

    const processPayment = () => dispatch(submit(PAY_FORM_ID));
    checkPaymentMethodFee(selectedPaymentMethod, processPayment);
  };

  return {
    payRequestError,
    isLoading,
    errorMessage,
    stripe,
    handleSubmitPaymentForm,
    onPayClick,
    valueToPay,
    invoicesData,
    isPaying,
  };
};
