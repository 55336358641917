import _ from "underscore";

import {
  Box,
  Button,
  Divider,
  Loader,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { usePayEmployerInvoices } from "@js/apps/invoices/hooks/use-pay-employer-invoices";
import { Modal } from "@js/components/modal";
import { formatCurrency } from "@js/utils";

import { PaymentMethodsContainer } from "../../containers/payment-methods";
import { PayFormComponent } from "../../forms/pay";
import NullStripeFeedback from "../null-stripe-feedback";

import { PaymentModalInvoicesList } from "./payment-modal-invoices-list";

import styles from "./style.module.scss";

const PAYMENT_MODAL_ID = "payment-modal";

export const PaymentModalInstance = Modal(PAYMENT_MODAL_ID);

export type PaymentModalContentProps = {
  invoiceIds: number[];
  consolidatedInvoiceId?: number;
  onInvoicesPaymentFailed?: () => void;
  onCloseSuccessModal?: () => void;
};

export const PaymentModalContent = ({
  onInvoicesPaymentFailed,
  onCloseSuccessModal,
  invoiceIds,
  consolidatedInvoiceId,
}: PaymentModalContentProps) => {
  const {
    isLoading,
    errorMessage,
    payRequestError,
    stripe,
    handleSubmitPaymentForm,
    onPayClick,
    valueToPay,
    invoicesData,
    isPaying,
  } = usePayEmployerInvoices({
    onCloseSuccessModal,
    onInvoicesPaymentFailed,
    closePaymentModal,
    invoiceIds,
    consolidatedInvoiceId,
  });

  return (
    <NullStripeFeedback stripe={stripe}>
      <Box className={styles.container}>
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size="small"
          sx={{ mb: 2 }}
        >
          Pay invoices
        </Typography>
        {isLoading && (
          <div className={styles.loaderOverlay}>
            <Loader centered />
          </div>
        )}
        <PaymentModalInvoicesList
          error={payRequestError}
          invoices={invoicesData ?? []}
        />
        <Box sx={{ mb: 1 }}>
          <Divider sx={{ borderColor: "var(--medium-beige)" }} />
        </Box>
        <PaymentMethodsContainer
          render={({
            fetchingPaymentMethods,
            paymentMethods,
            creatingPaymentMethod,
            addPaymentMethod,
            // canAddPaymentMethod -- no need to use; feedback handled by NullStripeFeedback
          }) => (
            <>
              <Stack sx={{ mb: 1 }}>
                <PayFormComponent
                  fetchingPaymentMethods={fetchingPaymentMethods}
                  paymentMethods={paymentMethods}
                  onSubmit={handleSubmitPaymentForm}
                  initialValues={{
                    paymentMethod: _.findWhere(paymentMethods, {
                      is_default: true,
                    }),
                  }}
                />
                {!!errorMessage && (
                  <Typography
                    component="p"
                    variant="paragraph"
                    size="small"
                    error
                    sx={{ textAlign: "center", mb: 2 }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: { xs: "end", sm: "space-between" },
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  disabled={creatingPaymentMethod}
                  variant="secondary"
                  shape="squared"
                  size="x-small"
                  onClick={addPaymentMethod}
                >
                  {creatingPaymentMethod ? "Saving..." : "Add Payment Method"}
                </Button>
                <Stack direction="row" sx={{ gap: 1 }}>
                  <Button
                    variant="secondary"
                    shape="squared"
                    size="x-small"
                    onClick={PaymentModalInstance.close}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    shape="squared"
                    size="x-small"
                    onClick={onPayClick}
                  >
                    {isPaying ? "Paying..." : "Pay"}
                    {typeof valueToPay !== "undefined" &&
                      ` ${formatCurrency(valueToPay)}`}
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
        />
      </Box>
    </NullStripeFeedback>
  );
};

type OpenPaymentModalArg = PaymentModalContentProps;

export const openPaymentModal = (paymentModalProps: OpenPaymentModalArg) => {
  PaymentModalInstance.open({
    children: <PaymentModalContent {...paymentModalProps} />,
    className: styles.paymentModal,
    padding: false,
    closeButtonProps: {
      className: styles.closeButton,
      size: "medium",
    },
  });
};

const closePaymentModal = () => {
  PaymentModalInstance.close();
};
