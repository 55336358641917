import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Tab, Tabs, Typography } from "@hexocean/braintrust-ui-components";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { RouterLink } from "@js/components/link";
import {
  EMPLOYER_ROUTES,
  getEmployerInvoicesMainRoute,
} from "@js/layouts/app/utils/employer-routes";
import type { Employer } from "@js/types/employer";
import { normalizePath } from "@js/utils/url";

export const EmployerInvoicesTabs = () => {
  const { pathname } = useLocation();
  const { data: employer } = useGetManagedEmployerQuery();
  const processedOptions = useMemo(() => {
    return getEmployerInvoicesTabsOptions(employer);
  }, [employer]);

  const selectedSpaceTabVal = useMemo(() => {
    const currentTab = processedOptions.find(
      ({ to }) => normalizePath(pathname) === normalizePath(to),
    );

    return currentTab ?? processedOptions[0];
  }, [pathname, processedOptions]);

  return (
    <Tabs
      aria-label="Invoices page tabs"
      id="invoicePageTabs" // used as an anchor to scroll the element into the view
      value={selectedSpaceTabVal.to}
      sx={{ minHeight: "38px" }}
    >
      {processedOptions.map(({ name, to }) => {
        return (
          <Tab
            key={name}
            value={to}
            to={to}
            component={RouterLink}
            sx={{
              minHeight: "38px",
              py: "6px",
            }}
            label={
              <Typography component="span" variant="label" size="medium">
                {name}
              </Typography>
            }
          />
        );
      })}
    </Tabs>
  );
};

const OPTIONS = [
  { name: "All invoices", to: EMPLOYER_ROUTES.INVOICES },
  { name: "Invoice batches", to: EMPLOYER_ROUTES.INVOICE_BATCHES },
];

const getEmployerInvoicesTabsOptions = (employer: Employer | undefined) => {
  if (!employer) {
    return OPTIONS;
  }

  const mainEmployerInvoicesRoute = getEmployerInvoicesMainRoute(employer);
  const optionsCopy = [...OPTIONS];
  return optionsCopy.sort((a) => {
    return a.to === mainEmployerInvoicesRoute ? -1 : 1;
  });
};
