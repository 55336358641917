import _ from "underscore";
import URI from "urijs";

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!
!!!!    Deprecated, please use @js/apps/filters
!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/

const defaultFilters = {
  page: "1",
  ordering: ENUMS.JobsListOrder.NEWEST,
  search: "",
  category: null,
  subcategory: null,
  contract_type: null,
  payment_type: null,
  role: null,
  skills: [],
  languages: [],
  timezones: [],
  fixed_price_budget_minimum_usd: null,
  hourly_budget_minimum_usd: null,
  invite_to_job: null,
  job: null,
  approved: null,
  start_date: null,
  end_date: null,
  employer: null,
  invoice_status: null,
  status: null,
  date_after: null,
  date_before: null,
  paid: null,
  unpaid: null,
  area: null,
  states: [],
  referral_link__user: null,
  user__account_type: null,
  bids_min_count: null,
  completed_jobs_min_count: null,
  active_jobs_min_count: null,
  user: null,
  freelancer: null,
  fee_invoices_only: null,
  balance_holder_id: null,
  amount: null,
  number: null,
  op_owners: [],
  sale_owners: [],
  unassigned_sales: null,
  unassigned_ops: null,
  action_performer: [],
  note: null,
  offer: null,
  skill_search_operator: null,
  availability_from: 5,
  availability_to: 40,
  company_node: null,
  page_size: null,
  freelancer_recommendations: null,
  recently_viewed: null,
  year: null,
  month: null,
  top_match: null,
};

export const buildFiltersURL = (filters: Record<string, any>) => {
  const arrayFilters = [
    "skills",
    "languages",
    "timezones",
    "action_performer",
    "op_owners",
    "sale_owners",
    "name",
    "email",
    "vesting_value",
    "amount",
  ];
  const andJoinedFilters = ["reaction"];
  const url = new URI();

  Object.keys(_.omit(defaultFilters, ...arrayFilters)).forEach((key) => {
    if (key in filters && (filters[key] || key === "approved")) {
      url.setQuery(key, filters[key]);
    } else {
      url.removeQuery(key);
    }
  });

  arrayFilters.forEach((key) => {
    const filtersQuery =
      key in filters && !!filters[key] && Array.isArray(filters[key])
        ? filters[key].join(",")
        : filters[key]?.toString();
    if (filtersQuery && filtersQuery.length) {
      url.setQuery(key, filtersQuery);
    } else {
      url.removeQuery(key);
    }
  });

  andJoinedFilters.forEach((key) => {
    if (key in filters && !!filters[key] && filters[key].length) {
      url.setQuery(key, filters[key]);
    } else {
      url.removeQuery(key);
    }
  });

  return url;
};

type FiltersType = Record<string, unknown>;

export const getChangeFilters = (
  existingFilters: FiltersType,
  newFilters: FiltersType,
) => {
  let updatedFilters: FiltersType = {};

  if (existingFilters.invite_to_job) {
    updatedFilters.invite_to_job = existingFilters.invite_to_job;
    updatedFilters.job = existingFilters.job;
  }

  if (typeof newFilters !== "undefined") {
    updatedFilters = {
      ...updatedFilters,
      ...existingFilters,
      ...newFilters,
    };
  }

  return _.omit(updatedFilters, "page");
};

export const paramsToObject = (search: string) => {
  return _.object([...new URLSearchParams(search).entries()]);
};

export const formatFilters = (
  filters?: Record<string, string | number | unknown[]>,
): Record<string, string> => {
  if (!filters) {
    return {};
  }

  const formattedFilters: Record<string, string> = {};
  for (const key in filters) {
    const value = filters[key];
    const formattedValue = Array.isArray(value)
      ? value.join(",")
      : value.toString();

    formattedFilters[key] = formattedValue;
  }

  return formattedFilters;
};
