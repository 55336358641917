import { useEffect } from "react";

import { useEffectRef } from "../use-effect-ref";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void | (() => void),
  delay: number | null,
  deps: unknown[] = [],
) => {
  const callbackRef = useEffectRef(callback);

  // Set up the interval.
  useEffect(() => {
    if (delay === null) {
      return;
    }

    function tick() {
      callbackRef.current();
    }

    const id = setInterval(tick, delay);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, callbackRef, ...deps]);
};
