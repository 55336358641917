import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import {
  findCurrentNavItem,
  getNavMenuColorVariant,
} from "@js/layouts/app/utils/common-navigation";
import { getEmployerNavItems } from "@js/layouts/app/utils/employer-navigation";

export const useEmployerSidebar = () => {
  const user = useUser();
  const { pathname } = useLocation();
  const { data: employer } = useGetManagedEmployerQuery();
  const canViewInvoices = !!user?.can_view_employer_invoices;

  const employerNavItems = useMemo(() => {
    if (!employer) {
      return [];
    }

    return getEmployerNavItems({ canViewInvoices, employer });
  }, [canViewInvoices, employer]);

  const currentLinkVariant = useMemo(() => {
    const currentLinkItem = findCurrentNavItem({
      items: employerNavItems,
      exact: true,
      pathname,
    });

    return getNavMenuColorVariant(currentLinkItem, pathname);
  }, [employerNavItems, pathname]);

  return {
    sidebarItems: employerNavItems,
    itemVariant: currentLinkVariant,
  };
};
