import { useFreelancerRole } from "@js/apps/freelancer/hooks/freelancer-role";

import type { NavItemsColorVariantsDictionary } from "./types";

export const useGetL1NavBarColorVariants = (pathname: string) => {
  const { role } = useFreelancerRole();
  const isAtRoleJobsLandingPage = pathname.includes("role-jobs");

  if (!isAtRoleJobsLandingPage || !role) return;

  return mapL1SpecificColorVariantToNavItemVariant(role.color);
};

const NavItemColorVariants: NavItemsColorVariantsDictionary = {
  green: "nav-green",
  violet: "nav-violet",
  blue: "nav-blue",
};

function mapL1SpecificColorVariantToNavItemVariant(color: string) {
  return NavItemColorVariants[color];
}
