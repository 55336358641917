import type { Employer } from "@js/types/employer";

export const EMPLOYER_ROUTES = {
  HOME: "/employer/dashboard/welcome/",
  MY_JOBS: "/employer/dashboard/my_jobs/",
  MY_TALENT: "/employer/dashboard/my_talent/",
  TALENT: "/talent/",
  INVOICES: "/employer/invoices/",
  INVOICE_BATCHES: "/employer/invoices/invoice_batches/",
};

export const getEmployerInvoicesMainRoute = (employer: Employer) => {
  return employer.consolidated_invoicing_enabled
    ? EMPLOYER_ROUTES.INVOICE_BATCHES
    : EMPLOYER_ROUTES.INVOICES;
};
